import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AnalysisKey, AuthLog, RDSAnalysisData } from '../../../model/analysis-details.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { DBService } from '../../../service/DB.service';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { User } from '../../../model/types';
import { bool } from 'aws-sdk/clients/signer';

@Component({
  selector: 'app-auth-log',
  templateUrl: './auth-log.component.html',
  styleUrls: ['./auth-log.component.scss']
})

export class AuthLogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<AuthLog>;
  tableColumns: string[] = ['userId', 'activityDate', 'eventResponse'];

  @ViewChild(MatPaginator, { static: false }) ch_paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) ch_sort: MatSort;
  logdata: AuthLog[] = [];
  temp_analysisData: any;

  authLogs: AuthLog[] = [];
  // role = sessionStorage.getItem('access');
  signedInUser: any;
  loading = false;
  LogstartDate;
  LogendDate;
  userID: string;
  showTable: boolean = false
  constructor(
    private dbService: DBService,
    private authService: AuthService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {

  }

  ngOnInit() {


    this.dbService.authLogs.subscribe(

      (data: AuthLog[]) => {

        if (data && data.length) {


          console.log('logdata DATA from Subscription', data)
          this.showTable = true
          // this.dataSource.data= data;
          this.dataSource = new MatTableDataSource(data);
          this.ref.detectChanges();
          this.dataSource.paginator = this.paginator;

          console.log(this.dataSource, "pp")
        }
        else {
          this.showTable = false;
        }
      }
    );


    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      if (!this.signedInUser || !this.signedInUser.signedIn) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      } else {
        this.authService.getUserAttributes((err1, userAttributes) => {
          this.loading = false;
          if (err1 || !userAttributes) {

            this.router.navigate(['/extra-layout/signin']);
          }
        });

        //     // this.dbService.getAnalysis(this.role);
        this.dbService.getAuthData(this.signedInUser.username, this.signedInUser.username, this.LogstartDate, this.LogendDate);
      }
    });





  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  openPDF() {
    const DATA: any = document.getElementById('authData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Login-Report.pdf');
    });
  }




  passSelectedDates() {
    this.logdata = []
    // console.log(this.LogstartDate);
    // console.log(this.LogendDate);
    // console.log(this.userID)
    console.log(sessionStorage.getItem('username'))
    const data = this.dbService.getAuthData(sessionStorage.getItem('username'), this.userID, this.LogstartDate, this.LogendDate);
  

  }

  selectedLogDates(dateRangeStart, dateRangeEnd, UserID) {
    this.LogstartDate = dateRangeStart.value;
    this.LogendDate = dateRangeEnd.value;
    this.userID = UserID.value;
    this.passSelectedDates();

  }

}
