import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AuthService} from '../../service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogHelpComponent } from './dialog-help/dialog-help.component';


@Component({
  moduleId: module.id,
  selector: 'navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() title: string;
  @Input() openedSidebar = false;
  @Output() sidebarState = new EventEmitter();
  signedInUserName: string;
  dialogCounter = 1;
  marginRightSignin: string;
  loadData: Window;
  constructor(private authService: AuthService, public dialog: MatDialog) {
  }

  open(event) {
    this.dialog.closeAll();
    const clickedComponent = event.target.closest('.nav-item');
    const items = clickedComponent.parentElement.children;

    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove('opened');
    }
    clickedComponent.classList.add('opened');
  }

  close(event) {
    const clickedComponent = event.target;
    const items = clickedComponent.parentElement.children;

    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove('opened');
    }
  }

  openSidebar() {
    this.openedSidebar = !this.openedSidebar;
    this.sidebarState.emit();
  }
  openDialog(data) {
    if(this.dialog.openDialogs.length==0){
    this.dialog.open(DialogHelpComponent, { data: { name: data }, disableClose: false });
  }
}
  ngOnInit() {
    this.marginRightSignin = '0px'
    this.authService.getCurrentUser((err, signedInUser) => {
      this.signedInUserName = signedInUser.username;
    });
  }
}
