import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Result, Table, outputGraph } from '../../model/small-molecule.model';
import { AnalysisDetails, SearchResult } from '../../model/analysis-details.model';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { SharedService } from '../../layouts/shared-service';
import { User } from '../../model/types';
import { DropDownService } from '../../service/drop-down.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { GraphDialogComponent } from './graph-dialog/graph-dialog.component';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { interval } from 'rxjs/internal/observable/interval';

@Component({
    selector: 'app-small-molecule',
    templateUrl: './small-molecule.component.html',
    styleUrls: ['./small-molecule.component.scss']
})
export class SmallMoleculeComponent implements OnInit, OnDestroy {

    pageTitle = 'Analyze Report/Data';
    signedInUser: any;
    analysisId = '';
    searchResult: SearchResult = <SearchResult>{};
    selectedTables: string;
    analysisDetails: AnalysisDetails;
    public tables: Table[] = [];
    routersub: ISubscription;
    callerSubscription: Subscription;
    tableSelected = false;
    tableProcessed = false;
    reviewTableSelection = false;
    public results: Result[];
    tempData: any;
    title = 'Loading';
    mysubscription: any;
    visibleData = 0;
    public showcharts = false;
    item;
    output_graph: outputGraph[];
    graphs: any[] = [];
    // public Histograms = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    // @ViewChild('textData') data: ElementRef;

    step = 0;
    updateOptions: Object;
    data: any;
    options: {};
    navigationSubscription: Subscription;
    usr: any;
    formType: any;
    currentUser: User;
    illegalUser: boolean;
    sData: SearchResult;
    role = sessionStorage.getItem('access');


    constructor(private _sharedService: SharedService,
        private activatedroute: ActivatedRoute,
        private authService: AuthService,
        private elRef: ElementRef,
        private router: Router,
        private dbService: DBService,
        private dropDownService: DropDownService,
        private downloadService: DownLoadService,
        public dialog: MatDialog,
        private ngxXml2jsonService: NgxXml2jsonService,
        private loader: AppLoaderService) {
        this._sharedService.emitChange(this.pageTitle);
        // if(this.tables.length==0){
        //     this.openDialog()
        // }


        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });




    }


    initialiseInvites() {
        this.authService.getCurrentUser((err, user: User) => {
            this.currentUser = user;
            this.activatedroute.paramMap.subscribe((params) => {
                if (params.get('user') !== this.currentUser.username) {
                    return
                } else {
                    this.usr = params.get('user');
                    this.analysisId = params.get('id');
                }

            });
        });

    }

    ngOnInit() {
        this.pollForResult();
        this.dbService.singleSearch.subscribe(
            (data: SearchResult) => {
                this.populateResultData(data);
            }
        );
        sessionStorage.removeItem('xyz');
    }


    openDialog() {
        this.loader.open(this.title);
    }

    closeDialog() {
        // console.log("dialog closed")
        this.loader.close();
    }

    // ngAfterViewInit() {
    //     let li = this.data.nativeElement.value;
    //     console.log("this value is", li);
    // }
    showCharts() {
        this.showcharts = true;
    }

    pollForResult() {
        // tslint:disable-next-line: max-line-length
        // if(this.searchResult.analysisStatus === 'TableResult' || this.searchResult.analysisStatus === 'Complete' || this.searchResult.analysisStatus === 'Error'){
        //     this.closeDialog();
        // }
        const callInterval = interval(10000);
        this.authService.getCurrentUser((err, user: User) => {
            this.signedInUser = user;
            if (!this.signedInUser || !this.signedInUser.signedIn) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.activatedroute.paramMap.subscribe((params) => {
                    // if (params.get('user') !== this.signedInUser.username ) {
                    //     this.illegalUser = true;
                    //     console.log(this.illegalUser);
                    //     return
                    // } else {
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    // }

                });
                if (!this.illegalUser) {
                    this.callerSubscription = callInterval.subscribe(() => {
                        const status = this.searchResult.analysisStatus;
                        console.log(status);
                        if ((status === 'TableResult' || status === 'Complete' || status === 'Error')) {
                            this.callerSubscription.unsubscribe();
                        } else {
                            this.dbService.getAnalysisDetails(this.signedInUser.username, this.analysisId);
                        }
                    })
                }
            }
        });
    }

    xmlToObj(xmlString: string) {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlString, 'text/xml');
        const obj = this.ngxXml2jsonService.xmlToJson(xml);
        this.tables = JSON.parse(JSON.stringify(obj))['Tables']['Table'];
        // if(this.tables.length){
        //     this.closeDialog()
        //   }
        // console.log('TABLE DATA ')
        // console.log(!this.tables.length);
        if (!this.tables.length) {
            // console.log('SPECIAL CASE');
            const table = {
                analyte_name: this.tables['analyte_name'],
                table_type: this.tables['table_type'],
                table_index: this.tables['table_index'],
                table_title: this.tables['table_title'],
                analysis_type: this.tables['analysis_type'],
                rows: this.tables['rows'],
                valid: this.tables['valid'],
                tb_title: this.tables['tb_title'],
                table_subtype: this.tables['table_subtype']
            };
            // console.log('table data', table);
            this.tables = [];
            this.tables.push(table)
        }
        // console.log('this.tables length ', this.tables.length);
        // console.log('this.tables ', this.tables);
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep(table: Table, selection: boolean) {
        table.valid = selection;
        this.step++;

        for (const obj of this.tables) {
            if (obj.valid === true || obj.valid === false) {
                this.tableSelected = true;
            } else {
                this.tableSelected = false;
                break;
            }
        }
    }

    submitTableData() {
        this.tableProcessed = true;
        this.reviewTableSelection = false;
        this.dbService.submitTableData(this.tables, this.signedInUser.username, this.analysisId).subscribe((data: Result[]) => {
            this.pollForResult();
        });
    }

    getCount(result: Result, color: string) {
        let count = 0
        for (const resultItem of result.resultItem) {
            for (const items of resultItem.item) {
                if (items.color === color) {
                    count = count + items.message.length
                }
            }
        }
        return count;
    }

    getColor(level: string): string {
        if (level === 'Green') {
            return '#C5EEB2'
        } else if (level === 'Yellow') {
            return '#F8F8A9'
        } else if (level === 'Info') {
            return '#75cff8'
        } else if (level === 'Red') {
            return '#EEC2B7'
        }
    }

    ngOnDestroy(): void {
        if (this.callerSubscription) {
            this.callerSubscription.unsubscribe();
        }
        if (this.mysubscription) {
            this.mysubscription.unsubscribe();
        }


    }

    processResult() {
        // console.log('Result Prcessor');

        for (const result of this.results) {
            for (const resultItem of result.resultItem) {
                for (const item of resultItem.item) {
                    item.colorCode = this.getColor(item.color);
                }
            }
        }
        this.formType = this.searchResult.analysisType;
        // console.log("actual link", this.searchResult.analysisResultFile);
        const output_file = this.getSingedURL(this.searchResult.analysisResultFile);
        this.searchResult.analysisResultFile = output_file
        const output_data = this.getSingedURL(this.searchResult.analysisResultData);
        this.searchResult.analysisResultData = output_data;
        console.log(this.searchResult);
        const output_file2 = this.getSingedURL(this.searchResult.AnalysisResultXlsxFile);
        this.searchResult.AnalysisResultXlsxFile = output_file2;
        try {
            this.output_graph = JSON.parse(this.searchResult.AnalysisResultGraph);
        } catch (error) {
        }
    }

    populateResultData(data: SearchResult) {
        console.log(data)
        this.searchResult = data;
        // TableSelected, NewAnalysis, Complete
        // if(this.searchResult.analysisStatus=== 'TableSelected' || this.searchResult.analysisStatus === 'Processed'){
        //     this.openDialog()
        // }
        // if(this.searchResult.analysisStatus === 'Complete' || this.searchResult.analysisStatus === 'Error'){
        //     this.closeDialog()
        // }
        const status = this.searchResult.analysisStatus;
        console.log('status', status);
        this.analysisDetails = JSON.parse(this.searchResult.analysisDetails);
        // console.log('ttt',this.analysisDetails);
        if (status === 'TableResult') {
            // this.analysisDetails = JSON.parse(this.searchResult.analysisDetails);
            // console.log('this.searchResult.tableData.length ', this.searchResult.tableData.length)
            if ((this.searchResult.tableData.length > 4)) {
                this.xmlToObj(this.searchResult.tableData);
            }
        } else if (status === 'TableSelected') {
            this.selectedTables = this.searchResult.selectedTables;
            // this.pollForResult()
        } else if (status === 'Complete') {
            // this.analysisDetails = JSON.parse(this.searchResult.analysisDetails);
            if ((this.searchResult.tableData.length > 4)) {
                this.xmlToObj(this.searchResult.tableData);
            }
            this.results = JSON.parse(this.searchResult.analysisResult);
            this.processResult();

        } else if (status === 'Error') {
            console.log('Error occured');
        }
    }

    tableFlag(text: string) {
        const flag = text.startsWith('<table') || text.startsWith('graph');
        return flag

    }

    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }

    getSingedURL(path: string) {
        // console.log(path);
        
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }

    review() {
        // console.log('Table data ', this.tables);
        // console.log('Last Selection ', JSON.parse(this.searchResult.selectedTables));
        const tableObjects: Table[] = JSON.parse(this.searchResult.selectedTables);
        const table_indexes: string[] = new Array();
        for (const tab of tableObjects) {
            table_indexes.push(tab.table_index)
        }

        for (const table of this.tables) {
            if (table_indexes.includes(table.table_index)) {
                table.valid = true;
            } else {
                table.valid = false;
            }
        }

        this.reviewTableSelection = true;
    }

    openGraph() {
        this.output_graph.forEach((element) => {
            const link = this.getSingedURL(element.key);
            const title = element.title;
            this.graphs.push(link);
        })
        this.dialog.open(GraphDialogComponent, { data: { name: this.graphs }, disableClose: false, height: '540px', width: '82%' });

    }
}
