export const environment = {
  production: false,
  serverUrl: 'https://tz4fzlbvkk.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'regression-dev-upload',
  userPool: {
    UserPoolId: 'us-east-2_8yst8FjeA',
    ClientId: '134d54iqq1s3o6fc6n3ucko540',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:f696f8f9-4d60-498d-b31b-0afb79ff4ac2',
  buckets: {
    'us-east-2': 'regression-dev-upload'
},
  defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:f696f8f9-4d60-498d-b31b-0afb79ff4ac2', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_8yst8FjeA', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '134d54iqq1s3o6fc6n3ucko540'
    },
    Storage: {
      AWSS3: {
        bucket: 'regression-dev-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
};

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_8yst8FjeA',
'aws_user_pools_web_client_id': '134d54iqq1s3o6fc6n3ucko540',
  'aws_cognito_mfa_configuration': 'OPTIONAL'
};
export default awsconfig;


