import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import { Router } from '@angular/router';
import { SignupForm } from '../../../model/types';
import {AuthService} from '../../../service/auth.service';
import {Result} from '../../../model/small-molecule.model';
import {DBService} from '../../../service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChallengeParameters } from '../../../service/cognito.service';
import { ToastrService } from 'ngx-toastr';
import {DialogComponent} from '../../dashboard/dialog/dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';



@Component({
  selector: 'app-signin',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class SigninComponent implements OnInit {

  mfaData = {
    destination: '',
    callback: null
  };
  email: string;
  username: string;
  password: string;
  freshUserPassword: string;
  freshUserConfirmPassword: string;
  submissionError: string;
  submissionSuccess: string;
  submitted = false;
  signinForm: FormGroup;
  firstTimePasswordForm: FormGroup;
  hide = true;
  // statusCode: string = '';
  statusCode = false;
  mfaStep = false;
  code: string;
  formErrors: SignupForm = {};
  errorMessage: string;
  show: boolean;
  submissionMFAError: string;
  firstTimePassword = false;
  isValid: boolean;
  userAttributes: any;
  infoMessage: string;
  expiryDateFlag = false;
  expiryDateUp = false;
  expiryDays;
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\s)(?=.*?[#?!@$%^&*-]).{8,}$'
  newUserCallback: (newPassword: string) => any;

  constructor(private authService: AuthService,
    private dbService: DBService,
    private router: Router,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone,
    public dialog: MatDialog
    ) {
    this.show = false;
  }

  passwordHide() {
    this.show = !this.show;
  }

  ngOnInit() {

    this.signinForm  =  this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.firstTimePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', Validators.required],
      disclaimer: [false, Validators.requiredTrue]
    });
    this.authService.setPreviousAppParams(this.router.routerState.snapshot.root.queryParams);
    this.authService.getCurrentUser((err, currentSignedInUser) => {
      if (currentSignedInUser && currentSignedInUser.signedIn) {
        this.authService.handleRedirect();
      }
    });

  }

  get formControls() { return this.signinForm.controls; }
  get newUserFormControls() { return this.firstTimePasswordForm.controls; }

  signin() {
    this.submitted = true;
    this.username = this.signinForm.value.username;
    this.password = this.signinForm.value.password;
    if (this.username == null || this.password == null) {
      this.errorMessage = 'All fields are required';
      return;
    }
    this.errorMessage = null;
    this.submissionError = '';
    this.authService.authenticate({username: this.username, password: this.password}, this);
  }

  private validateNewPassword() {
    this.isValid = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    if (this.freshUserConfirmPassword !== this.freshUserPassword) {
      this.isValid = false;
      this.submissionError = 'Confirm password should match new password.';
    }

    return this.isValid;
  }

  updatePassword() {
    if (!this.validateNewPassword()) {
      return
    }
    this.submitted = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    this.newUserCallback(this.freshUserPassword);
  }

  handleNewPasswordRedirect() {
    this.infoMessage = 'Password updated successfully. You will be rediredted to signIn page.'
    setTimeout(() => {
      this.firstTimePassword = false;
      this.submitted = false;
    }, 3000)
  }

  cognitoCallback(message: string, result: any) {

    if (message === 'SuccessWithoutMFA') {// success

      this.recordLoginData();
      this.authService.handleRedirect();
      setTimeout(() => {
        this.passwordExpiry();
      }, 8000)
      this.statusCode = false;
      this.submissionError = '';
    } else if (message === 'SuccessWithMFA') {

      this.recordLoginData();
      this.authService.handleRedirect();
      setTimeout(() => {
        this.passwordExpiry();
      }, 8000)
      this.submissionError = '';
      this.statusCode = false;
    } else if (message === 'New Password Updated.') {

      this.handleNewPasswordRedirect();
    } else {
      if (message != null) { // error
        this.errorMessage = message;

        if (this.errorMessage === 'User is not confirmed.') {

          this.router.navigate(['/home/confirmRegistration', this.username]);
        } else if (this.errorMessage === 'User needs to reset password.') {
          this.submissionError = '';
          this.submitted = false;
          this.firstTimePassword = true;
        } else if (this.errorMessage === 'Fail') {
          this.submissionMFAError = 'Incorrect OTP';

        } else if (this.errorMessage === 'FailWrongData') {
          this.statusCode = true;
          this.submissionError = 'Username or password is not valid.';

          this.submitted = false;
        } else {
          this.statusCode = true;
          this.submissionError = this.errorMessage;

          this.submitted = false;
        }
      }
    }
  }

  passwordExpiry () {
    this.authService.payloadData.take(1).subscribe((days) => {
      console.log(days);
      this.expiryDays = days;
        if (days > 5) {
          this.expiryDateFlag = false;
        // tslint:disable-next-line: triple-equals
        } else if (days <= 5 && days != 0) {
          this.expiryDateFlag = true;
          setTimeout(() => {
            this.openDialog('expiryError', this.expiryDays);
          }, 5000)
        // tslint:disable-next-line: triple-equals
        } else if (days == 0) {
          this.expiryDateUp = true;
          setTimeout(() => {
            this.openDialog('password expired', null);
            this.authService.signout();
          }, 1000)
        }
    })
  }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code == null || code.length === 0) {
        this.errorMessage = 'Code is required';
        return;
      }
      this.errorMessage = null;
      // test code is availabe or not

      callback(code);
    };


  }

  newUserPassword(userAttributes, requiredAttributes, callback: (newPassword: string) => any): void {
    this.newUserCallback = callback;
  }


  recordLoginData() {

    this.dbService.recordAuthEvent('login').subscribe((data: string) => {

    });
  }

  openDialog(data, days) {
    this.dialog.open(DialogComponent, {data: {name: data, days: days}, disableClose: false});
  }
}
