import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthNewService } from '../../../service/auth-new.service';

@Component({
  selector: 'app-user-new-password',
  templateUrl: './user-new-password.component.html',
  styleUrls: ['./user-new-password.component.scss']    
})
export class UserNewPasswordComponent implements OnInit {

  hide = true;
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*[()<> ])(?=.*?[#?!@$%^&*-]).{8,}$';
  show: boolean;
  submitted = false;
  firstTimePasswordForm: FormGroup;
  submissionError: string;
  firstTimePassword = false;
  freshUserPassword: string;
  freshUserConfirmPassword: string;
  infoMessage: string;
  isValid: boolean;
  user: CognitoUser;

  constructor(public formBuilder: FormBuilder, public router: Router, private authservice: AuthNewService) {
    this.show = false;
  }

  passwordHide() {
    this.show = !this.show;
  }
  get newUserFormControls() { return this.firstTimePasswordForm.controls; }
  
  ngOnInit(): void {
    this.firstTimePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', Validators.required],
      disclaimer: [false, Validators.requiredTrue]
    });
  }

  private validateNewPassword() {
    this.isValid = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    if (this.freshUserConfirmPassword !== this.freshUserPassword) {
      this.isValid = false;
      this.submissionError = 'Confirm password should match new password.';
    }

    return this.isValid;
  }

  updatePassword () {
    if (!this.validateNewPassword()) {
      return
    }
    this.submitted = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    this.authservice.newPassword(this.freshUserPassword)
    this.authservice.passwordChanged.subscribe((value) => {
      if (value === false) {
        return
      } else {
        this.infoMessage = 'Password has been updated successfully.'
        this.router.navigate(['/extra-layout/signin'])
      }
    })
  }

}
