// temporary Environment
export const environment = {
    production: false,
    serverUrl: 'https://sskejk87hg.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'kcas-reg-prod-upload',
    userPool: {
        UserPoolId: 'us-east-2_W5H8svVwi',
        ClientId: '24hqcfmkmr68dpbak9h7pbibtv',
        region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:8b711d02-cc75-444d-b0b1-749a2f93a204',

    defaultRegion: 'us-east-2',
    cognito: {
        identityPoolId: 'us-east-2:8b711d02-cc75-444d-b0b1-749a2f93a204', // REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-2', // REQUIRED - Amazon Cognito Region
        userPoolId: 'us-east-2_W5H8svVwi', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '24hqcfmkmr68dpbak9h7pbibtv', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
        AWSS3: {
            bucket: 'kcas-reg-prod-upload', // REQUIRED -  Amazon S3 bucket
            region: 'us-east-2', // OPTIONAL -  Amazon service region
        }
    }
};

const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_W5H8svVwi',
    'aws_user_pools_web_client_id': '24hqcfmkmr68dpbak9h7pbibtv',
    'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;


